import './style.css'

import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { DOCTOR_ME } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import {
  clinicalDepartmentNames,
  combineNames,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import { CLINICS_CONNECTION } from '@medical/pages/staff/Clinics/ClinicsList/ClinicsList.graphql'
import { DOCTORS_DEPARTMENTS } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import { useCustom } from '@medical/provider/context'
import React, { useEffect, useState } from 'react'
import { DOCTOR_WORK_SCHEDULES } from '../FindAvailableShift/FindAvailableShiftsence.graphql'
import moment from 'moment'
import DoctorHomeScene from './DoctorHomeScene'
import { checkOverlapTime } from '@medical/libs/checkOverlapTime'
import { CHECK_DOCTOR_CERT } from '@medical/constant'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

const DoctorHome = ({ popup, path, history }) => {
  const [isShowRemindCert, setIsShowRemindCert] = useState(false)
  const countFirstAccessHome = Number(localStorage.getItem('accessTime'))
  const [countFirstLogin, setCountFirstLogin] = useState(0)
  const [{ i18n, isDoctorBlocked }, { setIsDoctorBlocked }] = useCustom()
  const {
    loading: doctorMeLoading,
    error: doctorMeError,
    data: doctorMeData,
  } = useQuery(DOCTOR_ME)

  setIsDoctorBlocked(doctorMeData.doctorMe && doctorMeData.doctorMe.blocked)
  const { loading, data, error, refetch } = useQuery(CLINICS_CONNECTION, {
    variables: {
      where: {
        isSearchDisplay: true,
      },
    },
  })
  const {
    loading: clinicalDepartmentLoading,

    error: clinicalDepartmentErr,

    data: clinicalDepartmentData,
  } = useQuery(DOCTORS_DEPARTMENTS)

  // const { clinicalDepartments, departments } = clinicalDepartmentData
  const edges = data?.clinicsConnection.edges

  const {
    firstname,
    firstnameKana,
    lastname,
    lastnameKana,
    bankAccount,
    birthday,
    departments: doctorDepartments,
    files,
    gender,
    graduationUniversity,
    graduationUniversityYear,
    obtainDoctorLicenseYear,
    phoneNumber,
    placeOfWork,
    homeAddress,
    isJoinedOnlineDiagnosticTraining,
    insuranceDoctorNumber,
    insuranceDoctorSymbol,
    insuranceDoctorType,
  } = doctorMeData.doctorMe

  useEffect(() => {
    refetch()
  }, [])
  useEffect(() => {
    if (countFirstAccessHome) {
      localStorage.setItem('accessTime', countFirstAccessHome + 1)
    } else {
      localStorage.setItem('accessTime', 1)
    }
    setCountFirstLogin(Number(localStorage.getItem('accessTime')))
  }, [])
  useEffect(() => {
    if (!files) return
    const checkedCertificate =
      localStorage.getItem(CHECK_DOCTOR_CERT) === 'false'

    if (
      checkedCertificate &&
      !isJoinedOnlineDiagnosticTraining
    ) {
      setIsShowRemindCert(true)
      localStorage.setItem(CHECK_DOCTOR_CERT, true)
    }
  }, [files])

  const {
    loading: loadingWorkSchedules,
    error: errorWorkSchedules,
    data: dataWorkSchedules,
  } = useQuery(DOCTOR_WORK_SCHEDULES, {
    variables: {
      where: {
        id: doctorMeData.doctorMe && doctorMeData.doctorMe.id,
      },
      workSchedulesWhere2: {
        accepted: true,
        deletedAt: null,
        AvailableShift: {
          isPublished: true,
        },
        startTime_gte: moment()
          .startOf('day')
          .toISOString(),
      },
    },
    fetchPolicy: 'network-only',
  })

  if (
    loading ||
    clinicalDepartmentLoading ||
    doctorMeLoading ||
    loadingWorkSchedules
  )
    return <ProgressSpinner />
  if (error || clinicalDepartmentErr || doctorMeError || errorWorkSchedules)
    return (
      <ErrorComponent error={error || clinicalDepartmentErr || doctorMeError} />
    )

  const fileFiltered = files.filter(function(item) {
    if (
      item.fileCategory === 'DOCTOR_LICENSE' ||
      item.fileCategory === 'INSURANCE_DOCTOR_REGISTRATION'
    )
      return item
  })
  fileFiltered.sort((a, b) => {
    if (a && b) return a.fileCategory - b.fileCategory ? -1 : 1
  })
  if (fileFiltered.length === 1) {
    if (fileFiltered[0].fileCategory === 'INSURANCE_DOCTOR_REGISTRATION') {
      fileFiltered.unshift(null)
    }
  }
  const insuranceDoctor = !!(insuranceDoctorNumber && insuranceDoctorType && insuranceDoctorSymbol)
  const registeredItem = [
    gender,
    birthday,
    doctorDepartments,
    phoneNumber,
    homeAddress.zipCode,
    homeAddress.stateOrRegion,
    homeAddress.address1,
    placeOfWork,
    graduationUniversity,
    graduationUniversityYear,
    obtainDoctorLicenseYear,
    bankAccount,
    insuranceDoctor,
    fileFiltered[0],
    fileFiltered[1]
  ]

  const nameArray = [firstname, firstnameKana, lastname, lastnameKana]
  let missName = false
  for (let i = 0; i < nameArray.length; i += 1) {
    if (!nameArray[i]) {
      missName = true
      break
    }
  }
  if (missName) {
    registeredItem.unshift(null)
  } else registeredItem.unshift('name')
  let isAlert = false
  for (let i = 0; i < registeredItem.length; i += 1) {
    if (i === 3) {
      if (registeredItem[i].length === 0) {
        isAlert = true
        break
      }
    } else if (
      registeredItem[i] === null ||
      registeredItem[i] === undefined ||
      registeredItem[i] === ''
    ) {
      isAlert = true
      break
    }
  }

  const { clinicalDepartments, departments } = clinicalDepartmentData
  const departmentNames = clinicalDepartmentNames({ departments })
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,

    isFilter: true,
  })
  const getPublishedClinics = clinics => {
    const clinic = clinics.filter(item => item.node.isPublished === true)
    clinic.push({
      node: {
        id: 'INTERNAL_VACCINE_MEDICINE',
        name: '内科ワクチン専任',
      },
    })
    clinic.push({
      node: {
        id: 'CHILDREN_VACCINE_MEDICINE',
        name: '小児科ワクチン専任',
      },
    })
    return clinic
  }

  const listWorkPattern = [
    {
      value: 'ALL_WORKPATTTEN',
      description: i18n.t('main.ALL_WORK_PATTERN'),
    },
    {
      value: 'PERMANENT_DOCTOR',
      description: i18n.t('main.PERMANENT_DOCTOR'),
    },
    {
      value: 'REGULAR_SHIFT_DOCTOR',
      description: i18n.t('main.REGULAR_SHIFT_DOCTOR'),
    },
    {
      value: 'IRREGULAR_SHIFT_DOCTOR',
      description: i18n.t('main.IRREGULAR_SHIFT_DOCTOR'),
    },
    {
      value: 'TEMPORARY_DOCTOR',
      description: i18n.t('main.TEMPORARY_DOCTOR'),
    },
  ]
  const { workSchedules } = dataWorkSchedules.doctor
  const isRequestCert = !isJoinedOnlineDiagnosticTraining

  const checkWorkScheduleExits = item => {
    let isExits = false
    for (let index = 0; index < workSchedules.length; index++) {
      if (
        checkOverlapTime(
          workSchedules[index].startTime,
          workSchedules[index].endTime,
          item.startTime,
          item.endTime
        )
      ) {
        isExits = true
        break
      }
    }

    return isExits
  }
  return (
    <div>
      <DoctorHomeScene
        isDoctorBlocked={isDoctorBlocked}
        i18n={i18n}
        path={path}
        clinicListJobSearch={combineNames({
          clinicalDepartments: sortedClinicalDepartments,
          departmentNames,
        })}
        clinicList={getPublishedClinics(edges).map(event => event.node)}
        listWorkPattern={listWorkPattern}
        isAlert={isAlert}
        registeredItem={registeredItem}
        history={history}
        countFirstLogin={countFirstLogin}
        checkWorkScheduleExits={checkWorkScheduleExits}
        isRequestCert={isRequestCert}
      />

      <Dialog
        className={'dialog-show-remind-cert'}
        showHeader={false}
        visible={isShowRemindCert}
        modal
        onHide={() => setIsShowRemindCert(false)}
        footer={
          <div className='career-history-container'>
            <Button
              className='cancel-button'
              label='閉じる'
              style={{ padding: '2px 16px' }}
              onClick={() => setIsShowRemindCert(false)}
            />
            <Button
              className='reflect-button'
              label='登録情報を更新する'
              style={{ padding: '2px 1em' }}
              onClick={() => {
                history.push('/doctor/my_page')
              }}
            />
          </div>
        }
      >
        <div className='dialog-cret-content'>
          ※オンライン診療研修の受講 および
          オンライン診療研修修了証の提出をお願いします。※
        </div>
      </Dialog>
    </div>
  )
}

export default DoctorHome
